import React, { useRef, useEffect, forwardRef, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import * as Ai from "react-icons/ai";

const MediaPlayer = forwardRef((props, ref) => {
  const { userData, bookedUsersList } = useContext(AppContext);
  useEffect(() => {
    handleVideoMedia();
  }, [ref, props.videoTrack]);

  const handleVideoMedia = async () => {
    if (!ref || !ref.current || !props.videoTrack) return;
    await props.videoTrack.play(ref.current);
    props.onPlayerReady && props.onPlayerReady(ref.current);
    return () => {
      props.videoTrack.stop();
    };
  };

  useEffect(() => {
    handleAudioMedia();
  }, [props.audioTrack]);

  const handleAudioMedia = async () => {
    if (!props.audioTrack) return;
    await props.audioTrack.play();
    return () => {
      props.audioTrack.stop();
    };
  };

  const getRemoteUserById = () => {
    if (!props.remoteUserId) return;
    const uid = props.remoteUserId.substring(4);

    console.log("userId=>", uid);
    if (!bookedUsersList || bookedUsersList.length == 0) return;
    const user =
      bookedUsersList != null &&
      bookedUsersList.find((user) => uid == user._id);
    return user ? user.name : "Me";
  };

  console.log("userr", props.userName);

  return (
    <div
      ref={ref}
      id={props.id}
      className={props.classNames ? props.className : "video-player"}
      style={
        props.style
          ? props.style
          : {
              width: "320px",
              height: "240px",
              position: "relative",
              top: "0",
              left: "0",
            }
      }
    >
      <div
        className={!props.mainPlayer ? "name-screen main" : "name-screen side"}
      >
        {/* //! The username was hidden here. */}
        <span>
          {/* {props.remoteUserId && props.remoteUserId != null && (
            <span>
              {props.userName && !props.mainPlayer
                ? getRemoteUserById(props.remoteUserId)
                : "Unknown"}
            </span>
          )} */}
          {props.mainPlayer !== true && (
            <span>
              {props.userName != null && props.userName
                ? props.userName
                : getRemoteUserById()}
            </span>
          )}
          {props.adminName && (
            <span>
              <Ai.AiOutlineCrown /> {props.adminName}
            </span>
          )}
        </span>
      </div>
    </div>
  );
});

export default MediaPlayer;
