import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { AnimatePresence, motion } from "framer-motion";
import * as Ai from "react-icons/ai";
import Validator from "../../utils/validator";
import "./style.css";
import useForm from "../../hooks/useForm";
import signUpFormValidator from "../../helpers/signUpFormValidator";
import useAuth from "../../hooks/useAuth";
import { BeatLoader } from "react-spinners";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import { useNavigate } from "react-router";

function Signup(props) {
  const navigate = useNavigate();
  const { setIsSignUp } = useContext(AppContext);
  const initialValues = { fullName: "", username: "", password: "" };
  const [message, setMessage] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  //Here I used custom hook for validating the form
  const [formValues, formErrors, formValidator, onChange] = useForm(
    initialValues,
    (values) => signUpFormValidator(values)
  );

  //Hook for auth Api
  const { isLoading, setIsLoading, registerUser } = useAuth();

  useEffect(() => {
    console.log(isLoading ? "Loading" : "Waiting");
  }, [isLoading]);

  //This function is used to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    formValidator();
    if (Object.keys(signUpFormValidator(formValues)).length === 0) {
      signUp();
    }
  };
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      const sanitizedValue = value.replace(/\s+/g, "");
      onChange({ target: { name, value: sanitizedValue } });
    } else {
      onChange(e);
    }
  };

  //Finally, We're going to signup the user
  const signUp = async () => {
    const { fullName, username, password } = formValues;
    let isEmail = Validator.validateEmail(username);
    let isPhone = Validator.validatePhone(username);

    if (!isEmail && !isPhone) {
      setMessage("You must enter either Phone number or Email");
      setTimeout(async () => {
        setMessage(null);
      }, 2000);
      return;
    }
    let payload = {
      name: fullName,
      phonenumber: isPhone ? username : null,
      email: isEmail ? username : null,
      password: password,
      role: "user",
    };
    await registerUser(payload, {
      onSuccess: (res) => {
        console.log(res);
        window.sessionStorage.setItem(
          "RegisteredDetails",
          JSON.stringify({
            userId: res.userId,
            phonenumber: isPhone ? username : null,
            email: isEmail ? username : null,
            name: fullName,
          })
        );
        setTimeout(() => {
          setIsLoading(false);
          setIsSignUp("otp");

          if (props.checkoutPage === true) {
            navigate("/auth?isCheckout");
          }
        }, 1000);
      },
      onFailed: (error) => {
        setIsLoading(false);
        console.log("error", error.message);
        setMessage(error.message);
        setTimeout(() => {
          setMessage(null);
        }, 2000);
      },
    });
  };
  // const goToLogin = () => setIsSignUp(false);

  return (
    <>
      {message && (
        <ToastContainer position="top-end" className="p-3">
          <Toast>
            <Toast.Header>
              <strong className="me-auto">Message</strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
      <h3>Signup</h3>
      <AnimatePresence>
        <motion.div
          class="log_form"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <form onSubmit={handleSubmit} autoComplete="off">
            <input
              type="text"
              name="fullName"
              id=""
              class="form_field fullname "
              placeholder="Full Name"
              onChange={(e) => onChange(e)}
              value={formValues.fullName}
            />
            {formErrors.fullName ? (
              <span className="error_msg">{formErrors.fullName}</span>
            ) : (
              ""
            )}
            <input
              type="text"
              name="username"
              id=""
              class="form_field username mt-4"
              placeholder="Email"
              onChange={(e) => onChange(e)}
              value={formValues.username}
            />
            {formErrors.username ? (
              <span className="error_msg">{formErrors.username}</span>
            ) : (
              ""
            )}
            <input
              type={passwordType}
              name="password"
              id=""
              class="form_field password mt-4 mb-4"
              placeholder="Password"
              value={formValues.password}
              onChange={handlePasswordChange}
            />
            <i
              className="eye-btn-sign"
              onClick={togglePassword}
              style={{ cursor: "pointer" }}
            >
              {passwordType === "password" ? (
                <Ai.AiFillEyeInvisible />
              ) : (
                <Ai.AiFillEye />
              )}
            </i>
            {formErrors.password ? (
              <span className="error_msg">{formErrors.password}</span>
            ) : (
              ""
            )}
            <br />
            <button
              className="form_btn mt-3"
              id="formm"
              type="submit"
              disabled={isLoading}
            >
              {!isLoading ? (
                <>
                  Signup
                 
                </>
              ) : (
                <>
                  <BeatLoader
                    loading={isLoading}
                    color={"white"}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </>
              )}
            </button>
          </form>
          {/* <p className="signup_link">
            You already have an Account?{" "}
            <a onClick={() => goToLogin()}>Login</a>
          </p> */}
        </motion.div>
      </AnimatePresence>
    </>
  );
}
export default Signup;
