import React, { createContext, useState, useEffect } from "react";
import { io } from "socket.io-client";

export const SocketContext = createContext();

const SOCKET_URL = process.env.REACT_APP_API_BASE_URL;

export default function SocketContextProvider(props) {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    connectToSocket();
  }, []);

  const connectToSocket = () =>{
    const user = JSON.parse(window.localStorage.getItem("UserPersistent"));

    if (user != null) {
      const newSocket = io.connect(SOCKET_URL, {
        query: { token: user.accessToken },
      });

      // newSocket.on("test", (data) => console.log("Data", data));
      // newSocket.on("session-started", (data) =>
      //   console.log("session-started", data)
      // );
      // newSocket.on("session-stopped", (data) =>
      //   console.log("session-stopped", data)
      // );

      setSocket(newSocket);
    }
  }

  return (
    <SocketContext.Provider value={{ socket, connectToSocket }}>
      {props.children}
    </SocketContext.Provider>
  );
}
