import { useEffect, useState, useRef, useContext } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import BaseClient from "../helpers/base_client";
import { APIEndpoints } from "../constants/ApiEndPoints";
import CustomErrors from "../constants/Errors";
import { AppContext } from "../context/AppContext";
import { v4 as uuidv4 } from "uuid";

export default function useAgora(props) {
  const AGORA_CONFIG = { mode: "rtc", codec: "vp8" };
  const APP_ID = process.env.REACT_APP_AGORA_APP_ID;

  const [remotePublisher, setRemotePublisher] = useState(null);

  const { remoteUsers, setRemoteUsers, userDetailsLoading } =
    useContext(AppContext);

  const [localTracks, setLocalTracks] = useState({
    uid: null,
    localVideoTrack: null,
    localAudioTrack: null,
  });
  const [joinState, setJoinState] = useState(false);
  const [token, setToken] = useState(null);
  const [sharingScreen, setSharingScreen] = useState(false);
  const [muted, setMuted] = useState(false);
  const [videoHide, setVideoHide] = useState(false);
  const [joining, setJoining] = useState(false);
  const [error, setError] = useState(null);
  const [audioDevices, setAudioDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);

  const uid = useRef(null);
  const channelId = useRef(null);
  const client = useRef(null);
  const localScreenTracks = useRef(null);
  const localScreenAudioTracks = useRef(null);

  useEffect(() => {
    if (userDetailsLoading == null) return;
    getAgoraMediaDevices();
    if (!props.channelId) return;
    setJoining(true);
    channelId.current = props.channelId;
    const userDetails = window.sessionStorage.getItem("UserDetails");
    if (!window.sessionStorage.getItem("UserDetails")) {
      setError(CustomErrors.USER_CREDENTIALS_ERROR);
      setJoining(false);
      return;
    }
    createTokenForJoining(JSON.parse(userDetails)._id);
  }, []);

  async function getAgoraMediaDevices() {
    try {
      const devices = await AgoraRTC.getDevices();
      console.log("Agora Media Devices", devices);
      const audioDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setAudioDevices(audioDevices);
      setVideoDevices(videoDevices);
    } catch (error) {}
  }

  async function deviceChangeListener() {
    AgoraRTC.onCameraChanged((info) => {
      console.log("camera changed!", info.state, info.device);
    });
    AgoraRTC.onMicrophoneChanged((info) => {
      console.log("microphone changed!", info.state, info.device);
    });
  }

  async function createTokenForJoining(userId) {
    const payload = { uid: userId, role: "SUBSCRIBER" };
    await BaseClient.post(
      APIEndpoints.createBroadcastToken + `/${props.channelId}`,
      payload,
      {
        onSuccess: (res) => {
          console.log("Response", res.data);
          uid.current = res.data.uid;
          setToken(res.data.token);
        },
        onFailed: (error) => {
          console.log(error);
          switch (error.errorCode) {
            case "USER_NOT_BOOKED":
              setError(CustomErrors.USER_NOT_BOOKED_ERROR);
              return;
            case "SESSION_NOT_STARTED":
              setError(CustomErrors.SESSION_NOT_STARTED);
              return;
            case "SESSION_ENDED":
              setError(CustomErrors.SESSION_ENDED);
              return;
            default:
              setError(CustomErrors.UNEXPECTED_ERROR);
          }
        },
      }
    );
  }

  useEffect(() => {
    join();
  }, [token]);

  async function createTrack(config, type, devices, onSelectDevice) {
    let track = null;
    try {
      track =
        type === "video"
          ? await AgoraRTC.createCameraVideoTrack(config)
          : await AgoraRTC.createMicrophoneAudioTrack(config);
    } catch (error) {
      track = null;
    }

    if (track !== null) {
      const mediaTrack = track.getMediaStreamTrack();
      const settings = mediaTrack.getSettings();
      const device = devices.find(
        (device) => device.deviceId === settings.deviceId
      );
      onSelectDevice && onSelectDevice(device);
    }

    return track;
  }

  async function createLocalTracks(audioConfig, videoConfig) {
    const cameraTrack = await createTrack(
      videoConfig,
      "video",
      videoDevices,
      props.onSelectVideoDevice
    );

    const microphoneTrack = await createTrack(
      audioConfig,
      "audio",
      audioDevices,
      props.onSelectAudioDevice
    );

    setLocalTracks({
      localVideoTrack: cameraTrack,
      localAudioTrack: microphoneTrack,
    });

    return {
      cameraTrack,
      microphoneTrack,
    };
  }

  let join = async () => {
    try {
      if (joinState) return;

      if (!channelId.current || !token || !uid.current) {
        return;
      }

      if (client.current != null) return; 

      // Initialize Agora client
      client.current = AgoraRTC.createClient(AGORA_CONFIG);

      AgoraRTC.onCameraChanged = (info) => {
        console.log("camera changed!", info.state, info.device);
      };

      const { microphoneTrack, cameraTrack } = await createLocalTracks();

      console.log("channelId", channelId);

      // Join a channel
      await client.current.join(APP_ID, channelId.current, token, uid.current);

      if (cameraTrack != null) {
        await client.current.publish([cameraTrack]);
      }

      if (microphoneTrack != null) {
        await client.current.publish([microphoneTrack]);
      }

      setLocalTracks((prev) => {
        return {
          ...prev,
          uid: uid.current,
        };
      });

      setJoining(false);
      setJoinState(true);
    } catch (error) {
      setJoining(false);
      console.error("AGORA ERROR", error);
      switch (error.code) {
        case "PERMISSION_DENIED":
          setError(CustomErrors.USER_MEDIA_PERMISSION_ERROR);
          break;
        case "CAN_NOT_GET_GATEWAY_SERVER":
        case "CAN_NOT_GET_PROXY_SERVER":
        case "INVALID_UINT_UID_FROM_STRING_UID":
        case "UID_CONFLICT":
          setError(CustomErrors.USER_TOKEN_AUTHORIZATION_ERROR);
          break;
        case "NETWORK_ERROR":
        case "NETWORK_RESPONSE_ERROR":
        case "WS_ERR":
        case "WS_DISCONNECT":
        case "WS_ABORT":
          setError(CustomErrors.USER_CONNECTION_LOST_ERROR);
          break;
        case "NETWORK_TIMEOUT":
          setError(CustomErrors.USER_NETWORK_TIMEOUT_ERROR);
          break;
        case "UNEXPECTED_ERROR":
          setError(CustomErrors.UNEXPECTED_ERROR);
          break;
        case "DEVICE_NOT_FOUND":
          setError(CustomErrors.DEVICE_NOT_FOUND);
          break;
        default:
          setError(CustomErrors.UNEXPECTED_ERROR);
      }
    }
  };

  async function customAVTrackFromDevices(videoDevice, audioDevice, onSuccess) {
    try {
      if (videoDevice != null) {
        const videoConfig = {
          cameraId: videoDevice.deviceId,
        };
        const cameraTrack = await createTrack(
          videoConfig,
          "video",
          videoDevices,
          props.onSelectVideoDevice
        );

        localTracks.localVideoTrack.stop();

        await client.current.unpublish([localTracks.localVideoTrack]);

        console.log("localTracks.localVideoTrack", localTracks.localVideoTrack);

        await client.current.publish([cameraTrack]);

        setLocalTracks((prev) => {
          return {
            ...prev,
            localVideoTrack: cameraTrack,
          };
        });
      }

      if (audioDevice != null) {
        const audioConfig = { deviceId: audioDevice.deviceId };
        const microphoneTrack = await createTrack(
          audioConfig,
          "audio",
          audioDevices,
          props.onSelectAudioDevice
        );

        await client.current.publish([microphoneTrack]);
        setLocalTracks((prev) => {
          return {
            ...prev,
            localAudioTrack: microphoneTrack,
          };
        });
      }

      onSuccess && onSuccess();
    } catch (error) {
      console.error("CUSTOM TRACK ERROR", error);
    }
  }

  async function toggleScreenShare() {
    try {
      if (!client.current) return;

      if (sharingScreen == false) {
        const sharingScreenTrack = await AgoraRTC.createScreenVideoTrack(
          {},
          "auto"
        );

        let screenShareTrack = null;
        let systemAudioOrMicTrack = localTracks.localAudioTrack;

        if (Array.isArray(sharingScreenTrack)) {
          const [screenShare, audioTrack] = sharingScreenTrack;
          screenShareTrack = screenShare;
          systemAudioOrMicTrack = audioTrack;
          localScreenAudioTracks.current = audioTrack;
        } else {
          screenShareTrack = sharingScreenTrack;
        }

        if (screenShareTrack == null) {
          throw { message: "Unable to screen share" };
        }

        localScreenTracks.current = screenShareTrack;

        const mediaStreamTrack =
          await localScreenTracks.current.getMediaStreamTrack();

        if (mediaStreamTrack == null) return;

        await client.current.unpublish([
          localTracks.localVideoTrack,
          localTracks.localAudioTrack,
        ]);

        await client.current.publish([screenShareTrack]);

        if (systemAudioOrMicTrack != null) {
          await client.current.publish([systemAudioOrMicTrack]);
        }

        mediaStreamTrack.addEventListener("ended", () => {
          handleScreenshareEnd();
        });

        setSharingScreen(true);
      } else {
        handleScreenshareEnd();
      }
    } catch (error) {
      console.error("<---SCREENSHARE ERROR--->", error);
      setSharingScreen(false);
    }
  }

  async function handleScreenshareEnd() {
    const mediaStreamTrack =
      await localScreenTracks.current.getMediaStreamTrack();

    await mediaStreamTrack.stop();

    if (localScreenAudioTracks.current != null) {
      await localScreenAudioTracks.current.stop();
      await localScreenAudioTracks.current.close();
    }

    await client.current.unpublish();

    let videoTrack = localTracks.localVideoTrack;
    let audioTrack = localTracks.localAudioTrack;

    if (videoTrack == null) {
      const { cameraTrack } = await createLocalTracks();
      videoTrack = cameraTrack;
      setLocalTracks((prev) => {
        return {
          ...prev,
          localVideoTrack: cameraTrack,
        };
      });
    }

    if (audioTrack == null) {
      const { audioTrack } = await createLocalTracks();
      audioTrack = audioTrack;
      setLocalTracks((prev) => {
        return {
          ...prev,
          localAudioTrack: audioTrack,
        };
      });
    }

    await client.current.publish([videoTrack, audioTrack]);

    setSharingScreen(false);
    props.onSharingScreenEnded && props.onSharingScreenEnded();
  }

  async function toggleCamera() {
    if (!localTracks.localVideoTrack) return;
    if (localTracks.localVideoTrack.muted) {
      await localTracks.localVideoTrack.setMuted(false);
      setVideoHide(false);
    } else {
      await localTracks.localVideoTrack.setMuted(true);
      setVideoHide(true);
    }
  }

  async function toggleMic() {
    if (!localTracks.localAudioTrack) return;
    if (localTracks.localAudioTrack.muted) {
      await localTracks.localAudioTrack.setMuted(false);
      setMuted(false);
    } else {
      await localTracks.localAudioTrack.setMuted(true);
      setMuted(true);
    }
  }

  async function leave() {
    if (!client.current) return;
    if (localTracks.localAudioTrack) {
      await localTracks.localAudioTrack.stop();
      await localTracks.localAudioTrack.close();
    }
    if (localTracks.localVideoTrack) {
      await localTracks.localVideoTrack.stop();
      await localTracks.localVideoTrack.close();
    }
    setRemoteUsers([]);
    setJoinState(false);
    await client.current.leave();
    props.onUserLeave(uid.current);
  }
console.log("client.current", client.current);
  useEffect(() => {
    if (client.current) {
      setRemoteUsers([...client.current.remoteUsers]);
      console.log("Remote Users", client.current.remoteUsers);
      client.current.on("user-published", handleUserPublished);
      client.current.on("user-unpublished", handleUserUnpublished);
      client.current.on("user-joined", handleUserJoined);
      client.current.on("user-left", handleUserLeft);
    }

    return () => {
      if (client.current) {
        client.current.off("user-published", handleUserPublished);
        client.current.off("user-unpublished", handleUserUnpublished);
        client.current.off("user-joined", handleUserJoined);
        client.current.off("user-left", handleUserLeft);
      }
    };
  }, [client, joinState]);

  const handleUserPublished = async (user, mediaType) => {
    await client.current.subscribe(user, mediaType);
   
    console.log("client.current.remoteUsers", client.current.remoteUsers);
    // toggle rerender while state of remoteUsers changed.
    setRemoteUsers([...client.current.remoteUsers]);
  };
  const handleUserUnpublished = () => {
    setRemoteUsers([...client.current.remoteUsers]);
  };
  const handleUserJoined = () => {
    setRemoteUsers([...client.current.remoteUsers]);
  };
  const handleUserLeft = () => {
    setRemoteUsers([...client.current.remoteUsers]);
  };

  return {
    localTracks,
    localScreenTracks: localScreenTracks.current,
    localScreenTracks: localScreenTracks.current,
    sharingScreen: sharingScreen,
    remotePublisher,
    joinState,
    leave,
    join,
    joining,
    agoraError: error,
    remoteUsers,
    toggleCamera,
    toggleMic,
    muted,
    videoHide,
    toggleScreenShare,
    audioDevices,
    videoDevices,
    customAVTrackFromDevices,
  };
}
