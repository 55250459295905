export const APIEndpoints = {
  login: "user/login",
  register: "user/register",
  verifyOTP: "user/verifyotp",
  getCategories: "user/allcategories",
  getuserdetails: "user/getuserdetails",
  getAllCategories: "user/allcategories",
  findScheduleByDate: "user/findScheduleByDate",
  createPaymentIntent: "user/createpaymentIntent",
  getScheduledTherapies: "user/listscheduledtherapies",
  getBookedScheduleOfUser: "user/getBookedScheduleOfUser",
  createBroadcastToken: "user/createbroadcasttoken",
  updateUser: "user/updateuser",
  forgotPassword: "user/forgotpassword",
  otpVerification: "user/verifyotp",
  resetPassword: "user/resetpassword",
  getScheduledUsersList: "user/getBookedScheduleOfUser",
  createNMIPayment: "user/createPaymentnmi",
  getSingleTherapyData: "user/getallschedulelist",
  getAllInstructors: "user/allinstructors",
  getAllScheduleData: "user/allBookedSchedules",
  fileSharing: "chat/share-file",
  cancelSession: "user/cancel-session",
  participateGuest: "admin/guest-request",
  VerifyGuest: "admin/guest-verification",
  getGuestList: "admin/fetch-guest-list",
  rescheduleSession: "user/reschedule-session",
};
