import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import { Row, Spinner } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import * as Md from "react-icons/md";
import * as Gi from "react-icons/gi";
import * as Ai from "react-icons/ai";
import { AppContext } from "../../context/AppContext";
import { BeatLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import Formatter from "../../helpers/Formatter";
import useLocalStorage from "../../hooks/useLocalStorage";
import Utils from "../../utils/utils";
import placeholder from "../../assets/placeholder01.jpg";

function TherapyConfirmation({ onClick }) {
  const params = useParams();

  const [showMessage, setSHowMessage] = useState(true);
  const [therapy, setTherapy] = useState(null);
  const [isLoading, setIsLoading] = useState(-1);

  const {
    selectedBookingDate,
    setSelectedBookingDate,
    therapyLoading,
    findScheduleByDate,
    selectedDateSchedules,
    selectedScheduleSlot,
    setSelectedScheduleSlot,
    setDisableButton,
    therapies,
  } = useContext(AppContext);

  const { store, check } = useLocalStorage();


  useEffect(() => {
    getTherapyDetails();
  }, [therapies]);

  const getTherapyDetails = () => {
    const locTherapy = therapies.find(
      (therapy) => therapy.therapyId == params.id
    );
    if (!locTherapy) return;
    setTherapy(locTherapy);
  };

  const minDate = new Date();

  const findSchedule = (selectedDate) => {
    const date = new Date(Utils.convertToLocalDate(selectedDate));
    // console.log("date", date);
    if (!params.id) return;
    setSelectedScheduleSlot(null);
    findScheduleByDate({ date, therapyId: params.id });
    setSelectedBookingDate(date);
  };

  useEffect(() => {
    sessionStorage.setItem(
      "selectedTherapy",
      JSON.stringify(selectedScheduleSlot)
    );
  }, [selectedScheduleSlot]);

  const onSelectScheduleSlot = ({ schedule, slot, scheduleindex }) => {
    if (!slot || !schedule) return;
    setSelectedScheduleSlot({ schedule, slot });
    setIsLoading(scheduleindex);
    setTimeout(() => {
      onClick();
      setIsLoading(-1);
    }, 500);
  };

  return (
    <>
      {showMessage ? (
        <div class="alert alert-success mt-3 alert-msg" role="alert">
          Please Select a Slot and Continue!😊
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setSHowMessage(false)}
          >
            <Ai.AiFillCloseCircle />
          </button>
        </div>
      ) : (
        ""
      )}
      <h5 className="mt-4">Select Date</h5>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
          <div className="card calandar_card pb-4">
            <Calendar
              onChange={(e) => findSchedule(e)}
              minDate={minDate}
              value={selectedBookingDate}
              tileClassName={({ date }) => {}}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          {therapyLoading ? (
            <Spinner animation="border" variant="success" />
          ) : (
            <>
              {selectedDateSchedules.length != 0 ? (
                <div class="slots">
                  <h4>Available Slots</h4>
                  <div className="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                      {Array.isArray(selectedDateSchedules) &&
                        selectedDateSchedules.length != 0 &&
                        selectedDateSchedules.map((schedule, scheduleindex) => {
                          return (
                            <div className="mb-2" key={scheduleindex}>
                              {schedule.schedules.map((slot, slotIndex) => {
                                return (
                                  <div
                                    key={slotIndex}
                                    onClick={() =>
                                      onSelectScheduleSlot({
                                        schedule,
                                        slot,
                                        scheduleindex,
                                      })
                                    }
                                  >
                                    <div
                                      class={
                                        selectedScheduleSlot != null &&
                                        selectedScheduleSlot.slot._id ==
                                          slot._id
                                          ? "card slot_card active"
                                          : "card slot_card"
                                      }
                                    >
                                      {isLoading === scheduleindex ? (
                                        <div
                                          className="confirm-step spinner-border text-light"
                                          role="status"
                                        ></div>
                                      ) : (
                                        ""
                                      )}

                                      <p id="date">
                                        <Md.MdDateRange />{" "}
                                        {Formatter.formatDate(
                                          selectedBookingDate
                                        )}
                                      </p>
                                      <p id="time">
                                        <Gi.GiSandsOfTime /> {slot.startTime}-
                                        {slot.endTime}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      {selectedScheduleSlot &&
                        selectedScheduleSlot.schedule.therapistDetails && (
                          <div className="card therapist_details_">
                            <div className="tph_profile">
                              <img
                                src={
                                  selectedScheduleSlot != null
                                    ? process.env.REACT_APP_AWS_IMAGE_URL +
                                      selectedScheduleSlot.schedule
                                        .therapistDetails.photo
                                    : placeholder
                                }
                                alt=""
                              />
                            </div>
                            <p className="thp nm">
                              {
                                selectedScheduleSlot.schedule.therapistDetails
                                  .name
                              }
                            </p>
                            <p className="thp des">
                              {
                                selectedScheduleSlot.schedule.therapistDetails
                                  .desigination
                              }
                            </p>
                            <p className="thp exp">
                              {
                                selectedScheduleSlot.schedule.therapistDetails
                                  .experience
                              }{" "}
                              in{" "}
                              {
                                selectedScheduleSlot.schedule.therapistDetails
                                  .profession
                              }
                            </p>
                            <p className="thp">
                              {
                                selectedScheduleSlot.schedule.therapistDetails
                                  .email
                              }
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              ) : (
                <p>No Slots are Available</p>
              )}
            </>
          )}
        </div>
      </div>
      <br />
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="row">
          <div class="col-lg-8 col-md-12 col-sm-12 mt-2">
            {therapy && (
              <>
                <h5 className="thpy">Therapy Details</h5>
                <p className="therapy_title_">
                  {therapy.therapyDetails.therapyName}
                </p>
                <p className="therapy_description">
                  {therapy.therapyDetails.therapyDescription}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TherapyConfirmation;
