import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { AnimatePresence, motion } from "framer-motion";
import OTPInput, { ResendOTP } from "otp-input-react";
import "./style.css";
import useAuth from "../../hooks/useAuth";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import CustomMessages from "../../constants/Messages";
import { AppContext } from "../../context/AppContext";
import toast, { Toaster } from "react-hot-toast";

function OTPVerification() {
  const navigate = useNavigate();
  const location = useLocation();

  const [otp, setOtp] = useState("");
  const [registredData, setRegisteredData] = useState(null);
  const [message, setMessage] = useState(null);
  const { verifyUserOTP } = useAuth();
  const { setIsSignUp } = useContext(AppContext);
  const [isCheckout, setisCheckout] = useState(false)

  useEffect(() => {
    const data = window.sessionStorage.getItem("RegisteredDetails");

    if (!data) return;
    setRegisteredData(JSON.parse(data));
  }, []);

  useEffect(() => {
    if (location.search == "?isCheckout") {
      setisCheckout(true);
    }
  }, [location]);

  const handleOTP = async (e) => {
    e.preventDefault();
    if (!registredData || !otp) return;
    const payload = {
      userId: registredData.userId,
      otp: otp,
    };

    await verifyUserOTP(payload, {
      onSuccess: () => {
        toast.success(
          "Your registration is complete! Thanks for joining us and we can't wait to see you around!"
        );
        setTimeout(() => {
          setIsSignUp("login");
          window.sessionStorage.removeItem("RegisteredDetails");

          if(isCheckout) {
            navigate("/auth?checkout");
          } else {
            navigate("/auth");
          }
        }, 1000);
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      {message && (
        <ToastContainer position="top-end" className="p-3">
          <Toast>
            <Toast.Header>
              <strong className="me-auto">
                {CustomMessages.USER_REGISTERED_SUCCESSFULLY}
              </strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
      <br />
      <div className="otp-box">
        <h3 className="text-center mt-2">OTP Verification</h3>
        <p className="otp-sub text-center">
          A One-Time-Password has been sent to{" "}
          {registredData != null && registredData.email != null
            ? registredData.email
            : registredData != null && registredData.phone != null
            ? registredData.phone
            : "Unknown"}
        </p>
        <form onSubmit={(e) => handleOTP(e)}>
          <div className="otp-area">
            <OTPInput
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
            />
          </div>
          <button className="form_btn verify">Verify</button>
        </form>
        <p className="resend-otp">
          Not received? <span>Resend OTP</span>
        </p>
        <p className="text-center">
          Back to{" "}
          <button
            style={{ background: "transparent" }}
            onClick={() => setIsSignUp("login")}
          >
            login
          </button>{" "}
        </p>
      </div>
    </>
  );
}

export default OTPVerification;
