import React from "react";
import { useNavigate } from "react-router";

function LiveEnd() {
  const navigate = useNavigate();

  const removeStorage = () => {
    window.sessionStorage.clear();
    console.log("방송이 종료되었습니다.");
    window.localStorage.clear();
    navigate("/auth");
  };

  return (
    <div>
      <section class="leavepage mx-auto">
        <h1 className="text-center">You've left the meeting!</h1>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-4 col-md-4">
              <div className="session_end_img">
                <img src={require("../../assets/session-end.jpg")} alt="" />
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 position-relative">
              <div className="session-cont">
                <h6>You have been disconnected from the meeting.</h6>

                <p>
                  If you experienced technical difficulties, please try to
                  rejoin the meeting
                  <br />
                  Thank you for attending, and we hope to see you again soon.
                </p>
                <button class="rej-btn" onClick={() => navigate(-1)}>
                  Rejoin
                </button>
                <hr />
                <p className="pt-3">
                  By verifying the OTP,
                  <br /> you will become a verified user for Nest Healthcare.
                </p>
                <div className="d-flex">
                  <div class="btn_grp_">
                    <button
                      class="leave-btn rejoin"
                      onClick={() => navigate("/")}
                    >
                      Login
                    </button>
                  </div>
                  <button class="leave" onClick={() => removeStorage()}>
                    Visit Website
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        {/* <div className="container ">
          <hr className="line__" />
          <div className="error-box">
            <p className="session__end_info text-center">
              By verifying the OTP,
              <br /> you will become a verified user for Nest Healthcare.
            </p>
            <div className="mx-auto text-center">
              <div class="btn_grp_">
                <button class="leave-btn rejoin">Login</button>
                <br />
                <h6 className="mt-3">OR</h6>
              </div>
            </div>
          </div>
          <div className="mt-3 mx-auto text-center">
            <button class="leave" onClick={() => removeStorage()}>
              Visit Website
            </button>
          </div>
        </div> */}
      </section>
    </div>
  );
}

export default LiveEnd;
