import React, { useContext, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AppContext } from "../../context/AppContext";
import "./style.css";
import Validator from "../../utils/validator";
import useAuth from "../../hooks/useAuth";

import useForm from "../../hooks/useForm";
import * as Ai from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";

import loginFormValidator from "../../helpers/loginFormValidator";
import useLocalStorage from "../../hooks/useLocalStorage";
import { BeatLoader } from "react-spinners";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import { toast } from "react-hot-toast";

function Login(props) {
  const location = useLocation();
  const { setIsSignUp, setIsAuthenticated } = useContext(AppContext);
  const [isPassword, setIsPassword] = useState("");
  const initialValues = { username: "", password: "" };
  const [message, setMessage] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const [isCheckout, setisCheckout] = useState(false);

  useEffect(() => {
    if (location.search == "?checkout") {
      setisCheckout(true);
    }
  }, [location]);

  //This function is used to handle form submission
  const [formValues, formErrors, formValidator, onChange] = useForm(
    initialValues,
    (values) => loginFormValidator(values)
  );

  //Hook for auth Api
  const { isLoading, setIsLoading, loginUser } = useAuth();

  //Hook for local storage
  const { store, check } = useLocalStorage("UserPersistent");

  //Hook for navigation
  const navigate = useNavigate();

  //Code for handle submit form
  const handleSubmit = (e) => {
    e.preventDefault();
    formValidator();
    if (Object.keys(loginFormValidator(formValues)).length === 0) {
      login();
    }
  };
  useEffect(() => {
    console.log(isLoading ? "Loading" : "Waiting");
  }, [isLoading]);
  const handlePassword = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      const sanitizedValue = value.replace(/\s+/g, "");
      onChange({ target: { name, value: sanitizedValue } });
    } else {
      onChange(e);
    }
  };
  //Here, fially we can login the user
  const login = () => {
    const { username, password } = formValues;
    let isEmail = Validator.validateEmail(username);
    let isPhone = Validator.validatePhone(username);

    if (!isEmail && !isPhone) {
      setMessage("You Username/Password is incorrect");
      setTimeout(() => {
        setMessage(null);
      }, 2000);
      return;
    }
    let payload = {
      phonenumber: isPhone ? username : null,
      email: isEmail ? username : null,
      password: password,
    };
    loginUser(payload, {
      onSuccess: (data) => {
        setTimeout(() => {
          setIsLoading(false);
          const hasToken = data.accessToken;
          if (!hasToken) return;
          store(JSON.stringify(data));
          if (!check()) return;
          setIsAuthenticated(true);
          if (props.checkoutPage == true) {
            window.location.reload();
            return;
          }

          console.log(isCheckout);
          if (isCheckout) {
            const therapyid = sessionStorage.getItem("checkout-therapyid");
            window.location.href = `/payment/${therapyid}`;
            return;
          }

          window.location.href = "/";
        }, 1000);
      },
      onFailed: (error) => {
        setIsLoading(false);
        toast.error(error.message);
        setTimeout(() => {
          setMessage(null);
        }, 2000);
      },
    });
  };

  return (
    <>
      {/* {message && (
        <ToastContainer position="top-end" className="p-3">
          <Toast>
            <Toast.Header>
              <strong className="me-auto">Message</strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
          </Toast>
        </ToastContainer>
      )} */}
      <h2>Login</h2>
      <AnimatePresence>
        <motion.div
          class="log_form"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <form onSubmit={handleSubmit}>
            <div className="username mb-3">
              <input
                type="text"
                name="username"
                id=""
                class="form_field username"
                placeholder="Email"
                onChange={(e) => onChange(e)}
                value={formValues.username}
              />
              {formErrors.username ? (
                <span className="error_msg">{formErrors.username}</span>
              ) : (
                ""
              )}
            </div>
            <div className="password mb-2">
              <div className="position-relative">
                <input
                  type={passwordType}
                  onClick={(e) => setIsPassword(e.target.value)}
                  name="password"
                  id=""
                  class="form_field password"
                  placeholder="Password"
                  onChange={handlePassword}
                  value={formValues.password}
                />
                <i
                  className="word_eye"
                  onClick={togglePassword}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType === "password" ? (
                    <Ai.AiFillEyeInvisible />
                  ) : (
                    <Ai.AiFillEye />
                  )}
                </i>
              </div>
              {formErrors.password ? (
                <span className="error_msg p-viewer">
                  {formErrors.password}
                </span>
              ) : (
                ""
              )}
            </div>
            <br />
            <a className="forget" href="/forgot-password">
              Forgot Password?
            </a>
            <br />
            <button
              className="form_btn"
              id="formm"
              type="submit"
              disabled={isLoading}
            >
              {!isLoading ? (
                <>
                  Login
                  {/* <Ai.AiOutlineArrowRight /> */}
                </>
              ) : (
                <>
                  <BeatLoader
                    loading={isLoading}
                    color={"white"}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </>
              )}
            </button>
          </form>
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default Login;
