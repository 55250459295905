import React, {
  useContext,
  useEffect,
  useRef,
  useLayoutEffect,
  useState,
  Fragment,
} from "react";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import * as Md from "react-icons/md";
import * as Ri from "react-icons/ri";
import * as Fa from "react-icons/fa";
import * as Io from "react-icons/io";
import * as Si from "react-icons/si";
import { IoIosSend } from "react-icons/io";
import { CgSoftwareDownload } from "react-icons/cg";
import Placeholder from "../../assets/avatar2.png";
import "./LiveSession.css";
import { Row, Col, Container, Badge } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MediaPlayer from "../../pages/StreamPage/Mediaplayer";
import useChat from "../../hooks/useChat";
import { useParams } from "react-router";
import toast, { Toaster } from "react-hot-toast";

function ChatSession(props) {
  const { id } = useParams();
  const newMsg = useRef(null);
  const uploadRef = useRef(null);
  const pinnedref = useRef(false);
  const {
    remoteUsers,
    userDetails,
    players,
    setPlayers,
    fileSharingToUser,
    fileLoading,
    bookedTherapies,
  } = useContext(AppContext);
  const [msgNotif, setMsgNotif] = useState(false);
  const [shareFile, setShareFile] = useState(null);
  const [currentTherapy, setCurrentTherpy] = useState(null);

  const {
    chatRoomId,
    message,
    chatMessages,
    clearChat,
    sendMessageToRoom,
    setMessage,
  } = useChat({
    chatRoomId: props.chatRoomId,
    userDetails,
    userType: "Client",
    onMessageRecieved: (message) => {
      if (message != null && message.userType === "Therapist") {
        setMsgNotif(true);
        setTimeout(() => {
          setMsgNotif(true);
          toast("New Message Received!", {
            icon: "🚩",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }, 1800);
        setMsgNotif(false);
        // console.log("message from", msgNotif);
      }
    },
  });
  //?? For Therapist we'e have to change the userDetails Object & userType to Therapist.

  const onChangeMessageField = (e) => {
    setMessage(e.target.value);
  };

  const onPressKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      sendMessageToRoom();
    }
  };

  useEffect(() => {
    initFunction();
  }, [bookedTherapies]);

  useEffect(() => {
    const container = newMsg.current;
    if (!pinnedref.current) {
      container.scrollTop = container.scrollHeight;
    }
  });

  const initFunction = () => {
    if (
      !bookedTherapies ||
      !Array.isArray(bookedTherapies) ||
      bookedTherapies.length == 0
    ) {
      return;
    }

    const therapyData = bookedTherapies.find((therapy) => therapy._id == id);
    setCurrentTherpy(therapyData);
  };

  useLayoutEffect(() => {
    const container = newMsg.current;
    const pinned =
      container.scrollHeight - container.scrollTop === container.clientHeight;
    pinnedref.current = pinned;
    console.log((pinnedref.current = pinned));
  });

  // useEffect(() => {
  //   console.log("bookedUsersList", bookedUsersList);
  // }, [bookedUsersList]);

  // const getRemoteUserById = (id) => {
  //   const uid = id.substring(4);

  //   if (!bookedUsersList || bookedUsersList.length == 0) return;
  //   const user = bookedUsersList.find((user) => uid == user._id);
  //   if (!user) return;
  //   setuserData(user)
  //   return user.name;
  // };

  console.log("shareFile", shareFile);

  const handleFileUpload = async () => {
    if (!shareFile) {
      sendMessageToRoom();
      return;
    }

    const formData = new FormData();

    formData.append("file", shareFile);
    formData.append("roomId", id);
    formData.append("bookingId", id);
    formData.append("userType", "Client");
    formData.append("username", userDetails != null ? userDetails.name : "N/A");
    formData.append("userId", userDetails != null ? userDetails._id : "N/A");

    await fileSharingToUser(formData, {
      onSuccess: (res) => {
        toast.success(res.message);
        setShareFile(null);
        console.log("fileUpload response", res);
      },
      onFailed: (err) => {
        console.error(err);
      },
    });
  };

  console.log("remoteUsers", remoteUsers);

  return (
    <>
      <Toaster position="top-center" />
      <Container>
        <div className="chat_main">
          <div className="chat_head">
            <div className="tab-div mx-auto">
              <Tabs defaultActiveKey="All" className="mx-auto m-3" justify>
                <Tab
                  eventKey="All"
                  title={
                    <span style={{ fontSize: "13px" }}>
                      <i style={{ fontSize: "17px", marginRight: "2px" }}>
                        <Ri.RiUserSmileFill />
                      </i>{" "}
                      Participants
                    </span>
                  }
                  id="scrollable"
                >
                  <div className="scroll-area">
                    {remoteUsers.length != 0 &&
                      props.localTracks != null &&
                      Object.keys(props.localTracks).length != 0 && (
                        <div className="card part_card mx-auto">
                          <MediaPlayer
                            id={"sidePlayer"}
                            videoTrack={props.localTracks.localVideoTrack}
                            audioTrack={props.localTracks.localAudioTrack}
                            style={{ aspectRatio: "16/9" }}
                            ref={React.createRef()}
                            userName={"Me"}
                          />
                        </div>
                      )}
                    {remoteUsers.map((user, index) => {
                      console.log(user.uid);
                      const player = players.find((p) => p.uid === user.uid);

                      console.log("user=>", user.uid);

                      if (!player) {
                        const newPlayer = {
                          uid: user.uid,
                          ref: React.createRef(),
                        };
                        setPlayers([...players, newPlayer]);
                      }

                      const pinnedUserId = window.sessionStorage.getItem(
                        "client-pinnedUserId"
                      );
                      if (pinnedUserId == user.uid) {
                        return null;
                      }

                      // const matches = props.checkMainPlayerUidMatches(user.uid);
                      // if (matches == true) {
                      //   return null;
                      // }

                      return (
                        <>
                          <div className="card part_card mx-auto">
                            <div className="cont">
                              <div key={user.uid} className="mini-player">
                                <button
                                  className="pin_btn"
                                  onClick={() => {
                                    const currentPlayer = players.find(
                                      (p) => p.uid === user.uid
                                    );
                                    return (
                                      props.onClickPin &&
                                      props.onClickPin({
                                        index,
                                        videoTrack: user.videoTrack,
                                        audioTrack: user.audioTrack,
                                        uid: user.uid,
                                        player:
                                          currentPlayer &&
                                          currentPlayer.ref.current,
                                      })
                                    );
                                  }}
                                >
                                  <Bs.BsFillPinAngleFill />
                                </button>
                                {player && (
                                  <MediaPlayer
                                    id={`player-${user.uid}`}
                                    videoTrack={user.videoTrack}
                                    audioTrack={user.audioTrack}
                                    style={{ aspectRatio: "16/9" }}
                                    onPlayerReady={(player) => {
                                      return (
                                        props.onPlayerReady &&
                                        props.onPlayerReady(player)
                                      );
                                    }}
                                    ref={player.ref}
                                    remoteUserId={user.uid}
                                    mainPlayer={false}
                                    temp="temp"
                                  />
                                )}
                                <button className="btn-cont">
                                  {!user.hasAudio ? (
                                    <Bs.BsMicFill />
                                  ) : (
                                    <Bs.BsMicMuteFill />
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </Tab>

                <Tab
                  eventKey="Messages"
                  title={
                    <Fragment>
                      <span style={{ fontSize: "13px" }}>
                        <i style={{ fontSize: "17px", marginRight: "2px" }}>
                          <Si.SiGooglemessages />
                        </i>{" "}
                        Messages
                      </span>
                      {msgNotif ? (
                        <Badge bg="danger" variant="danger" className="ms-2">
                          1
                        </Badge>
                      ) : (
                        ""
                      )}
                    </Fragment>
                  }
                  className="tab"
                >
                  <div className="chat_area_left mt-3" id="chat" ref={newMsg}>
                    <div class="chat_info">
                      <h5>Please let us know if you face any issues.</h5>
                      <h6>
                        <Ai.AiOutlineMail /> mail@nesthealthcare.com
                      </h6>
                    </div>

                    {/* chat Content */}
                    {fileLoading && (
                      <div class="animate__animated animate__fadeInUp uploading">
                        uploading..
                      </div>
                    )}

                    {userDetails &&
                      Array.isArray(chatMessages) &&
                      chatMessages.length != 0 &&
                      chatMessages.map((message) => {
                        const currentUser = message.userId == userDetails._id;

                        let filename;

                        if (message != null && message.type === "file") {
                          const fileUrl = message.fileUrl;
                          const parts = fileUrl.split("/");
                          filename = parts[parts.length - 1];
                        }

                        return !currentUser ? (
                          <Fragment>
                            <div class="chat d-flex mt-2">
                              <div class="avt">
                                <img
                                  src={
                                    currentTherapy != null &&
                                    currentTherapy.therapistDetails
                                      ? process.env.REACT_APP_AWS_IMAGE_URL +
                                        currentTherapy.therapistDetails.photo
                                      : Placeholder
                                  }
                                  alt=""
                                />
                              </div>
                              {/* <p className="user_name_chat"> {currentTherapy != null &&
                            currentTherapy.therapistDetails
                              ? currentTherapy.therapistDetails.name
                              : "N/A"}</p> */}
                              {message != null && message.type === "file" ? (
                                <div class="txt">
                                  <div class="shared_file">
                                    <a
                                      href={
                                        process.env
                                          .REACT_APP_IMAGE_URL_FILE_SHARE +
                                        message.fileUrl
                                      }
                                      class="file_download"
                                      target="_blank"
                                    >
                                      <i>
                                        <CgSoftwareDownload />
                                        <a />
                                      </i>
                                      <span>{filename}</span>
                                    </a>
                                  </div>
                                </div>
                              ) : (
                                <div class="txt">
                                  <div className="txt_cont">
                                    {message.message}
                                  </div>
                                </div>
                              )}
                            </div>
                          </Fragment>
                        ) : (
                          <div class="chat-right d-flex mt-2">
                            {message != null && message.type === "file" ? (
                              <div class="txt">
                                <div class="shared_file mt-2">
                                  <a
                                    href={
                                      process.env
                                        .REACT_APP_IMAGE_URL_FILE_SHARE +
                                      message.fileUrl
                                    }
                                    class="file_download"
                                    target="_blank"
                                  >
                                    <i>
                                      <CgSoftwareDownload />
                                      <a />
                                    </i>
                                    <span>{filename}</span>
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <div class="txt">
                                <div className="txt_cont">
                                  {message.message}
                                </div>
                              </div>
                            )}
                            <div class="avt" id="right">
                              <img
                                src={
                                  userDetails.photo != null
                                    ? process.env.REACT_APP_AWS_IMAGE_URL +
                                      userDetails.photo
                                    : require("../../assets/logo_main.png")
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        );
                      })}

                    {/* chat content end */}
                  </div>
                  {/* Chat Area end */}
                  <div className="sent-box">
                    <input
                      className="p-3 msg-content"
                      placeholder={
                        shareFile != null
                          ? shareFile.name
                          : "write a message...."
                      }
                      onChange={onChangeMessageField}
                      onKeyDown={onPressKeyDown}
                      value={message}
                      disabled={shareFile != null ? true : false}
                    />
                    <button
                      class="file_button"
                      onClick={() => {
                        uploadRef.current.click();
                      }}
                    >
                      <Md.MdAttachFile />
                    </button>
                    <input
                      type="file"
                      name=""
                      id="fileUpload"
                      ref={uploadRef}
                      style={{ display: "none" }}
                      onChange={(e) => setShareFile(e.target.files[0])}
                    />
                    <button class="send_" onClick={handleFileUpload}>
                      <IoIosSend />
                    </button>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default ChatSession;
