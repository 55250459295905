export default class Utils {
  static checkObjectAllValuesDefined(obj) {
    for (let prop in obj) {
      if (typeof obj[prop] === "undefined") {
        return false;
      }
    }
    return true;
  }

  static createFormDataFromFormValues(values) {
    const form = new FormData();

    Object.keys(values).forEach(function (key, index) {
      form.append(key, values[key]);
    });

    return form;
  }
  static checkStringIsNumber(value) {
    let str = value;
    let values = parseInt(str);

    if (!isNaN(values)) {
      console.log("original value", value);
      return values;
    }
  }

  static convertToNormalURL(url) {
    const base64Image = url.split(";base64,").pop();
    const blob = new Blob([atob(base64Image)], { type: "image/png" });
    return URL.createObjectURL(blob);
  }

  static createBlobFile(base64String) {
    const binaryData = atob(base64String.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: "image/png" });
    return new File([blob], "image.png");
  }

  static convertToLocalDate(date) {
    const localDate = new Date(date);

    const offsetMinutes = localDate.getTimezoneOffset();

    const utcDate = new Date(localDate.getTime() - offsetMinutes * 60000);

    const utcString = utcDate.toISOString();
    return utcString;
  }

  static getNextDaysOfWeek(numOfDays, startingDay) {
    const days = [];
    const today = new Date();

    // Days of the week, indexed by 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Find the index of the specified starting day
    const startingDayIndex = daysOfWeek.indexOf(startingDay);

    if (startingDayIndex === -1) {
      console.error("Invalid starting day.");
      return days;
    }

    // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const currentDay = today.getDay();

    // Calculate the number of days until the specified starting day
    const daysUntilStartingDay = (7 - currentDay + startingDayIndex) % 7;

    for (let i = 0; i < numOfDays; i++) {
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + daysUntilStartingDay + i * 7);

      days.push(nextDay);
    }

    return days;
  }

  static formatDate(value) {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Create a normal date string
    const normalDate = `${day}-${month}-${year}`;

    return normalDate;
  }
}
