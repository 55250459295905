// Packages
import React, { useState, useEffect } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Container, Card, Button, Row } from "react-bootstrap";
import Slider from "react-slick";
import Lottie from "lottie-react";
import Aos from "aos";
// import { useNavigate } from "react-router";

// Components
import HealthAnomation from "../../assets/lottie/43792-yoga-se-hi-hoga.json";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Header2 from "../../components/Header2/Header2";
import BottomBar from "../../components/BottomBar/BottomBar";
import Transitions from "../../Transition";
import Popup from "../../components/Popup/Popup";

// icons
import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import * as Fa from "react-icons/fa";
import * as Bi from "react-icons/bi";
import * as Md from "react-icons/md";
import * as Im from "react-icons/im";

// css
import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";

function Home() {
  Aos.init();

  // const navigate = useNavigate();
  const [panel, setPanel] = useState("1");
  const [videoUp, setVideoUp] = useState(false);
  const [list, setList] = useState([]);

  const panelChange = (event, newPanel) => {
    setPanel(newPanel);
  };
  var sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Transitions>
        {/* header Starts */}
        <header>
          <Header />
          <section className="banner">
            <Container>
              <div className="banner-sec">
                <div
                  className="lottie_bg"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <Lottie animationData={HealthAnomation}></Lottie>
                </div>
                <div className="banner-cont">
                  <h1 data-aos="fade-up" data-aos-duration="2000">
                    Changing lives
                  </h1>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    style={{ textAlign: "justify" }}
                  >
                    <span>
                      Welcome Nest Healthcare Aftercare Online Therapies:{" "}
                    </span>{" "}
                    <br />
                    At our Aftercare Online Therapies site, we don't just offer
                    support; we empower you to transform your life. We
                    understand that the journey to recovery and well-being
                    continues beyond therapy sessions, and that's where our
                    aftercare services is here to support you through every step
                    of the way. We're not just a platform.
                  </p>
                </div>
                <div class="d-flex" data-aos="fade-up" data-aos-duration="2000">
                  <button
                    className="course_btn"
                    onClick={() => (window.location.href = "/therapies")}
                  >
                    Start a Therapy
                  </button>
                </div>
              </div>

              <h1
                class="subb_title"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                What We Do
              </h1>
              <div className="row c_row">
                <div className="col-lg-4 col-md-4 col-sm-4 col_c">
                  <div
                    className="card banner-items"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <div className="bc_img">
                      <img src={require("../../assets/ICO/plant.png")} />
                    </div>
                    <p>Identify the Root Cause</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4.col-sm-4 col_c">
                  <div
                    className="card banner-items"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <div className="bc_img">
                      <img src={require("../../assets/ICO/medication.png")} />
                    </div>
                    <p>Building a Personalised & Responsive Treatment Plan</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4.col-sm-4 col_c">
                  <div
                    className="card banner-items"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <div className="bc_img">
                      <img src={require("../../assets/ICO/spa.png")} />
                    </div>
                    <p>Effective aftercare & Long-term recovery</p>
                  </div>
                </div>
              </div>
            </Container>
          </section>
        </header>
        {/* Header End */}
        {/* Main Content Start */}
        <main>
          <section className="trial">
            <h4 className="text-center therapy-trial">
              Why Choose Our Aftercare Online Therapies?
            </h4>
            <Container>
              <div className="row">
                <div className="col-md-6 col-sm-6 content-why">
                  <div className="d-flex why-boxes ">
                    <div className="row">
                      <div className="col-md-4">
                        <h6 className="data-head">
                          Empowerment Through Continuity
                        </h6>
                      </div>
                      <div className="side-div"></div>
                      <div className="col-md-7">
                        <p className="para-wy">
                          Our commitment doesn't end when your session does. We
                          provide continuous support that nurtures your growth
                          and recovery. Our aftercare program is designed to
                          ensure your newfound skills and insights are applied
                          in the real world, resulting in a better chance for
                          lasting change.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 content-why">
                  <div className="d-flex why-boxes therapy-box">
                    <div className="row">
                      <div className="col-md-4">
                        <h6 className="data-head">Tailored to Your Needs</h6>
                      </div>
                      <div className="side-div"></div>
                      <div className="col-md-7">
                        <p className="para-wy">
                          We hope that during your residential stay you would be
                          been able to build a rapport and trust with our team
                          of therapist, Every individual's path to healing is
                          unique. Our aftercare services are highly personalized
                          to cater to your specific requirements, ensuring you
                          receive the right aftercare therapist and resources,
                          tools, and guidance to thrive independently.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 content-why">
                  <div className="d-flex why-boxes therapy-box">
                    <div className="row">
                      <div className="col-md-4 ">
                        <h6 className="data-head">Expert Guidance</h6>
                      </div>
                      <div className="side-div"></div>
                      <div className="col-md-7">
                        <p className="para-wy">
                          Our team comprises experienced therapists and
                          counselors who will be your companions on your
                          aftercare journey. They bring unparalleled expertise
                          to help you navigate life's challenges and capitalize
                          on opportunities.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 content-why">
                  <div className="d-flex why-boxes therapy-box">
                    <div className="row">
                      <div className="col-md-4">
                        <h6 className="data-head">Holistic Approach</h6>
                      </div>
                      <div className="side-div"></div>
                      <div className="col-md-7">
                        <p className="para-wy">
                          We understand that well-being encompasses more than
                          just your mental health. Our aftercare program
                          considers your physical health, emotional balance, and
                          social integration, offering a holistic approach that
                          takes into account every facet of your life.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 content-why">
                  <div className="d-flex why-boxes therapy-box">
                    <div className="row">
                      <div className="col-md-4">
                        <h6 className="data-head">A Supportive Community</h6>
                      </div>
                      <div className="side-div"></div>
                      <div className="col-md-7">
                        <p className="para-wy">
                          Join a vibrant community of like-minded individuals
                          who are walking similar paths. Connect, share, and
                          find strength in the knowledge that you're not alone
                          in your journey.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section>
          {/* <section>
            <Container>
              <div className="row">
                <h6 className="text-center head-h">
                  Why Choose Our Aftercare Online Therapies?
                </h6>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <Box
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1500"
                  >
                    <TabContext value={panel}>
                      <Box
                        sx={{
                          borderBottom: "1px",
                          borderColor: "darkolivegreen",
                        }}
                      >
                        <TabList aria-label="Tabs" onChange={panelChange}>
                          <Tab
                            className="programPanel"
                            label="Empowerment Through Continuity"
                            value="1"
                          />
                          <Tab
                            className="programPanel"
                            label="Tailored to Your Needs"
                            value="2"
                          />
                          <Tab
                            className="programPanel"
                            label="Expert Guidance"
                            value="3"
                          />
                          <Tab
                            className="programPanel kidProgram"
                            label="Holistic Approach"
                            value="4"
                          />
                          <Tab
                            className="programPanel kidProgram"
                            label="A Supportive Community"
                            value="5"
                          />
                        </TabList>
                        <TabPanel className="panel_cont" value="1">
                          <Container>
                            <Popup trigger={videoUp} setTrigger={setVideoUp} />

                            <div className="panel_img">
                              <button
                                class="video_layer"
                                onClick={() => setVideoUp(true)}
                              >
                                <Ai.AiFillPlayCircle />
                              </button>
                              <img
                                src={require("../../assets/video/video.png")}
                              />
                            </div>
                            <p className="panel_cont">
                              Our commitment doesn't end when your session does.
                              We provide continuous support that nurtures your
                              growth and recovery. Our aftercare program is
                              designed to ensure your newfound skills and
                              insights are applied in the real world, resulting
                              in a better chance for lasting change.
                            </p>
                          </Container>
                        </TabPanel>
                        <TabPanel className="panel_cont" value="2">
                          <Container>
                            <p className="panel_cont">
                              We hope that durring your residential stay you
                              would be been able to build a rapport and trust
                              with our team of therapist, Every individual's
                              path to healing is unique. Our aftercare services
                              are highly personalized to cater to your specific
                              requirements, ensuring you receive the right
                              aftercare therapist and resources, tools, and
                              guidance to thrive independently.
                            </p>
                          </Container>
                        </TabPanel>
                        <TabPanel className="panel_cont" value="3">
                          <Container>
                            <p className="panel_cont">
                              Our team comprises experienced therapists and
                              counselors who will be your companions on your
                              aftercare journey. They bring unparalleled
                              expertise to help you navigate life's challenges
                              and capitalize on opportunities.
                            </p>
                          </Container>
                        </TabPanel>
                        <TabPanel className="panel_cont" value="4">
                          <Container>
                            <p className="panel_cont">
                              We understand that well-being encompasses more
                              than just your mental health. Our aftercare
                              program considers your physical health, emotional
                              balance, and social integration, offering a
                              holistic approach that takes into account every
                              facet of your life.
                            </p>
                          </Container>
                        </TabPanel>
                        <TabPanel className="panel_cont" value="5">
                          <Container>
                            <p className="panel_cont">
                              Join a vibrant community of like-minded
                              individuals who are walking similar paths.
                              Connect, share, and find strength in the knowledge
                              that you're not alone in your journey
                            </p>
                          </Container>
                        </TabPanel>
                      </Box>
                    </TabContext>
                  </Box>
                </div>
              </div>
            </Container>
          </section> */}
          <section className="programmes">
            <Container>
              <h1
                className="program_title"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="40"
                data-aos-duration="1000"
              >
                Our Aftercare Services
              </h1>

              <div className="p_card">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div
                      class="card pro_card1"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="50"
                      data-aos-duration="1000"
                    >
                      <div className="card-img-top">
                        <img
                          src={require("../../assets/home-data/check-in.jpg")}
                        />
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline-bottom"></span>
                        </div>
                      </div>
                      <div class="card-body proCard">
                        <h5 class="card-title">Regular Check-Ins</h5>
                        <p class="card-text">
                          Scheduled online check-ins with your therapist,
                          ensuring you stay on track and continue making
                          progress.
                        </p>
                        {/* <a
                          href="/therapies"
                          className="know_more"
                          title="Know more"
                        >
                          {" "}
                          <i>
                            <Bs.BsFillArrowRightSquareFill />
                            <span>know more</span>
                          </i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div
                      class="card pro_card1"
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-delay="60"
                      data-aos-duration="1000"
                    >
                      <div className="card-img-top">
                        <img
                          src={require("../../assets/home-data/human-resources-concept-with-man.jpg")}
                        />
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline-bottom"></span>
                        </div>
                      </div>

                      <div class="card-body proCard">
                        <h5 class="card-title">Resource Hub</h5>
                        <p class="card-text">
                          Access to a wealth of educational materials, articles,
                          and exercises designed to bolster your emotional
                          resilience and life skills.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div
                      class="card pro_card1"
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-delay="70"
                      data-aos-duration="1000"
                    >
                      <div className="card-img-top">
                        <img
                          src={require("../../assets/home-data/members-support-group-putting-their-hands-together-make-high-five-cheerful-young-women-men-celebrating-their-successful-counseling-session.jpg")}
                        />
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline-bottom"></span>
                        </div>
                      </div>
                      <div class="card-body proCard">
                        <h5 class="card-title">Group Support</h5>
                        <p class="card-text">
                          Engage in group sessions, discussions, and workshops
                          to foster peer connections, share experiences, and
                          gain additional insights.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div
                      class="card pro_card1"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="50"
                      data-aos-duration="1000"
                    >
                      <div className="card-img-top">
                        <img
                          src={require("../../assets/home-data/medium-shot-people-learning.jpg")}
                        />
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline-bottom"></span>
                        </div>
                      </div>
                      <div class="card-body proCard">
                        <h5 class="card-title">Lifestyle Coaching</h5>
                        <p class="card-text">
                          Receive guidance on nutrition, exercise, and
                          mindfulness practices that enhance your overall
                          well-being.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div
                      class="card pro_card1"
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-delay="60"
                      data-aos-duration="1000"
                    >
                      <div className="card-img-top">
                        <img src={require("../../assets/home-data/goal.jpg")} />
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline-bottom"></span>
                        </div>
                      </div>
                      <div class="card-body proCard">
                        <h5 class="card-title">Goal Setting</h5>
                        <p class="card-text">
                          Collaborate with our professionals to set and achieve
                          meaningful life goals, from career aspirations to
                          personal development milestones.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div
                      class="card pro_card1"
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-delay="70"
                      data-aos-duration="1000"
                    >
                      <div className="card-img-top">
                        <img src={require("../../assets/home-data/sup.jpg")} />
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline-bottom"></span>
                        </div>
                      </div>
                      <div class="card-body proCard">
                        <h5 class="card-title">24/7 Access</h5>
                        <p class="card-text">
                          We're here when you need us. Our platform is
                          accessible 24/7, so you can connect with your support
                          network whenever you require assistance
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section>
          <section className="final">
            <h1
              className="final-title"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="40"
              data-aos-duration="1000"
            >
              Your Journey to Empowerment Starts Here
            </h1>
            <p
              className="final-para"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Recovery is a journey, and it doesn't have to be walked alone. At
              our Aftercare Online Therapies site, we provide the tools,
              resources, and unwavering support you need to not just survive but
              thrive. Rediscover your power, embrace life's opportunities, and
              forge a path towards a brighter, more empowered future. Your
              well-being matters. Choose our Aftercare Online Therapies, and
              take charge of your destiny
            </p>
          </section>
          {/* <section className="instructor">
            <Container>
              <h1
                className="program_title"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="40"
                data-aos-duration="1000"
              >
                Our Skilled Instructors
              </h1>
              <p
                className="program_sub"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Smply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry’s standard dummy text ever since the
                1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book.
              </p>
              <Row>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card"
                    data-aos="fade-right"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div className="crImg">
                      <img
                        src={require("../../assets/instructors/instructor-1.png")}
                        class="card-img-top"
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Aleksejs Petenko</h5>
                      <span>Teacher</span>
                      <p class="card-text">
                        Lobortis arcu, a vestibulum augue. Vivamus ipsum neque,
                        facilisis vel mollis vitae.
                      </p>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <div className="social d-flex">
                          <a href="/">
                            <Fa.FaFacebookF />
                          </a>
                          <a href="/">
                            <Bs.BsTwitter />
                          </a>
                          <a href="/">
                            <Fa.FaLinkedinIn />
                          </a>
                          <a href="/">
                            <Bs.BsInstagram />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div className="crImg">
                      <img
                        src={require("../../assets/instructors/instructor-2.png")}
                        class="card-img-top"
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Anna Kunytska</h5>
                      <span>Teacher</span>
                      <p class="card-text">
                        Lobortis arcu, a vestibulum augue. Vivamus ipsum neque,
                        facilisis vel mollis vitae.
                      </p>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <div className="social d-flex">
                          <a href="/">
                            <Fa.FaFacebookF />
                          </a>
                          <a href="/">
                            <Bs.BsTwitter />
                          </a>
                          <a href="/">
                            <Fa.FaLinkedinIn />
                          </a>
                          <a href="/">
                            <Bs.BsInstagram />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card"
                    data-aos="fade-left"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div className="crImg">
                      <img
                        src={require("../../assets/instructors/instructor-3.png")}
                        class="card-img-top"
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Ben Daniel</h5>
                      <span>Teacher</span>
                      <p class="card-text">
                        Lobortis arcu, a vestibulum augue. Vivamus ipsum neque,
                        facilisis vel mollis vitae.
                      </p>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <div className="social d-flex">
                          <a href="/">
                            <Fa.FaFacebookF />
                          </a>
                          <a href="/">
                            <Bs.BsTwitter />
                          </a>
                          <a href="/">
                            <Fa.FaLinkedinIn />
                          </a>
                          <a href="/">
                            <Bs.BsInstagram />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </Row>
            </Container>
          </section> */}
          {/* <section class="events">
            <Container>
              <h1
                className="program_title"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="40"
                data-aos-duration="1000"
              >
                Upcoming Events
              </h1>
              <p
                className="program_sub"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry’s standard dummy text ever
                since the 1500s.
              </p>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card event_card"
                    data-aos="zoom-in-left"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div className="eImg">
                      <img
                        src={require("../../assets/events/event1.jpg")}
                        class="card-img-top"
                      />
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-8" style={{ padding: "20px" }}>
                          <h5 class="card-title">TED Talk</h5>
                          <div class="d-flex">
                            <span>
                              <i>
                                <Bi.BiTime />
                              </i>{" "}
                              07:10 PM
                            </span>
                            <span style={{ paddingLeft: "20px" }}>
                              <i>
                                <Md.MdOutlineLocationOn />
                              </i>{" "}
                              Melbourne
                            </span>
                          </div>
                        </div>
                        <div class="col-4" style={{ padding: "0" }}>
                          <div class="ev_date">
                            <h1>8</h1>
                            <h2>June</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card event_card"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div className="eImg">
                      <img
                        src={require("../../assets/events/event3.jpg")}
                        class="card-img-top"
                      />
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-8" style={{ padding: "20px" }}>
                          <h5 class="card-title">
                            Conference and Dinner with Our Clients
                          </h5>
                          <div class="d-flex">
                            <span>
                              <i>
                                <Bi.BiTime />
                              </i>{" "}
                              07:10 PM
                            </span>
                            <span style={{ paddingLeft: "20px" }}>
                              <i>
                                <Md.MdOutlineLocationOn />
                              </i>{" "}
                              Dubai
                            </span>
                          </div>
                        </div>
                        <div class="col-4" style={{ padding: "0" }}>
                          <div class="ev_date">
                            <h1>11</h1>
                            <h2>Aug</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card event_card"
                    data-aos="zoom-in-right"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div className="eImg">
                      <img
                        src={require("../../assets/events/event2.jpg")}
                        class="card-img-top"
                      />
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-8" style={{ padding: "20px" }}>
                          <h5 class="card-title">
                            Crash Course for Senior sitizen
                          </h5>
                          <div class="d-flex">
                            <span>
                              <i>
                                <Bi.BiTime />
                              </i>{" "}
                              11:00 AM
                            </span>
                            <span style={{ paddingLeft: "20px" }}>
                              <i>
                                <Md.MdOutlineLocationOn />
                              </i>{" "}
                              Newyork
                            </span>
                          </div>
                        </div>
                        <div class="col-4" style={{ padding: "0" }}>
                          <div class="ev_date">
                            <h1>20</h1>
                            <h2>May</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section> */}
          {/* <section class="teachers">
            <div class="container">
              <h1
                className="program_title"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="40"
                data-aos-duration="1000"
              >
                What Our Teacher’s Say
              </h1>
              <p
                className="program_sub pb-10"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="40"
                data-aos-duration="1000"
              >
                Simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry’s standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </p>
              <div
                class="zoom-out-down"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="60"
                data-aos-duration="1000"
              >
                <Slider {...sliderSettings}>
                  <div class="slick_slide">
                    <div class="card slicks">
                      <div class="row">
                        <div class="col-4" style={{ position: "relative" }}>
                          <div class="slick-img">
                            <img
                              src={require("../../assets/instructors/instructor-1.png")}
                              alt="teachers"
                            />
                          </div>
                          <div class="slick_logo">
                            <a href="/">
                              <Fa.FaFacebookF />
                            </a>
                            <a href="/">
                              <Bs.BsTwitter />
                            </a>
                            <a href="/">
                              <Fa.FaLinkedinIn />
                            </a>
                            <a href="/">
                              <Bs.BsInstagram />
                            </a>
                          </div>
                        </div>
                        <div class="col-8 p-2 slickss">
                          <h2 className="slick_name text-left">Edwin Kimro</h2>
                          <p className="text-sm leading-tight">Teacher</p>
                          <p className="slick_card-cont">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Quo ea rerum, quam similique obcaecati
                            assumenda a. Nisi cupiditate atque praesentium sint
                            delectus facilis vero quo! Facilis nobis unde
                            voluptate accusantium.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slick_slide">
                    <div class="card slicks">
                      <div class="row">
                        <div class="col-4" style={{ position: "relative" }}>
                          <div class="slick-img">
                            <img
                              src={require("../../assets/instructors/instructor-2.png")}
                              alt="teachers"
                            />
                          </div>
                          <div class="slick_logo">
                            <a href="/">
                              <Fa.FaFacebookF />
                            </a>
                            <a href="/">
                              <Bs.BsTwitter />
                            </a>
                            <a href="/">
                              <Fa.FaLinkedinIn />
                            </a>
                            <a href="/">
                              <Bs.BsInstagram />
                            </a>
                          </div>
                        </div>
                        <div class="col-8 p-2 slickss">
                          <h2 className="slick_name text-left">
                            Jaquline Fernadez
                          </h2>
                          <p className="text-sm leading-tight">Teacher</p>
                          <p className="slick_card-cont">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Quo ea rerum, quam similique obcaecati
                            assumenda a. Nisi cupiditate atque praesentium sint
                            delectus facilis vero quo! Facilis nobis unde
                            voluptate accusantium.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slick_slide">
                    <div class="card slicks">
                      <div class="row">
                        <div class="col-4" style={{ position: "relative" }}>
                          <div class="slick-img">
                            <img
                              src={require("../../assets/instructors/instructor-4.png")}
                              alt="teachers"
                            />
                          </div>
                          <div class="slick_logo">
                            <a href="/">
                              <Fa.FaFacebookF />
                            </a>
                            <a href="/">
                              <Bs.BsTwitter />
                            </a>
                            <a href="/">
                              <Fa.FaLinkedinIn />
                            </a>
                            <a href="/">
                              <Bs.BsInstagram />
                            </a>
                          </div>
                        </div>
                        <div class="col-8 p-2 slickss">
                          <h2 className="slick_name text-left">Harsha Bogal</h2>
                          <p className="text-sm leading-tight">Teacher</p>
                          <p className="slick_card-cont">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Quo ea rerum, quam similique obcaecati
                            assumenda a. Nisi cupiditate atque praesentium sint
                            delectus facilis vero quo! Facilis nobis unde
                            voluptate accusantium.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slick_slide">
                    <div class="card slicks">
                      <div class="row">
                        <div class="col-4" style={{ position: "relative" }}>
                          <div class="slick-img">
                            <img
                              src={require("../../assets/instructors/instructor-5.png")}
                              alt="teachers"
                            />
                          </div>
                          <div class="slick_logo">
                            <a href="/">
                              <Fa.FaFacebookF />
                            </a>
                            <a href="/">
                              <Bs.BsTwitter />
                            </a>
                            <a href="/">
                              <Fa.FaLinkedinIn />
                            </a>
                            <a href="/">
                              <Bs.BsInstagram />
                            </a>
                          </div>
                        </div>
                        <div class="col-8 p-2 slickss">
                          <h2 className="slick_name text-left">
                            Thomas Piatro
                          </h2>
                          <p className="text-sm leading-tight">Teacher</p>
                          <p className="slick_card-cont">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Quo ea rerum, quam similique obcaecati
                            assumenda a. Nisi cupiditate atque praesentium sint
                            delectus facilis vero quo! Facilis nobis unde
                            voluptate accusantium.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slick_slide">
                    <div class="card slicks">
                      <div class="row">
                        <div class="col-4" style={{ position: "relative" }}>
                          <div class="slick-img">
                            <img
                              src={require("../../assets/instructors/instructor-6.png")}
                              alt="teachers"
                            />
                          </div>
                          <div class="slick_logo">
                            <a href="/">
                              <Fa.FaFacebookF />
                            </a>
                            <a href="/">
                              <Bs.BsTwitter />
                            </a>
                            <a href="/">
                              <Fa.FaLinkedinIn />
                            </a>
                            <a href="/">
                              <Bs.BsInstagram />
                            </a>
                          </div>
                        </div>
                        <div class="col-8 p-2 slickss">
                          <h2 className="slick_name text-left">
                            William Rodwik
                          </h2>
                          <p className="text-sm leading-tight">Teacher</p>
                          <p className="slick_card-cont">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Quo ea rerum, quam similique obcaecati
                            assumenda a. Nisi cupiditate atque praesentium sint
                            delectus facilis vero quo! Facilis nobis unde
                            voluptate accusantium.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slick_slide">
                    <div class="card slicks">
                      <div class="row">
                        <div class="col-4" style={{ position: "relative" }}>
                          <div class="slick-img">
                            <img
                              src={require("../../assets/instructors/instructor-3.png")}
                              alt="teachers"
                            />
                          </div>
                          <div class="slick_logo">
                            <a href="/">
                              <Fa.FaFacebookF />
                            </a>
                            <a href="/">
                              <Bs.BsTwitter />
                            </a>
                            <a href="/">
                              <Fa.FaLinkedinIn />
                            </a>
                            <a href="/">
                              <Bs.BsInstagram />
                            </a>
                          </div>
                        </div>
                        <div class="col-8 p-2 slickss">
                          <h2 className="slick_name text-left">
                            Alexandra jermiah maxie
                          </h2>
                          <p className="text-sm leading-tight">Teacher</p>
                          <p className="slick_card-cont">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Quo ea rerum, quam similique obcaecati
                            assumenda a. Nisi cupiditate atque praesentium sint
                            delectus facilis vero quo! Facilis nobis unde
                            voluptate accusantium.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </section> */}
          <BottomBar />
        </main>
        {/* main content End */}
        {/* Footer starts */}
        <footer>
          <Footer />
        </footer>
      </Transitions>
    </>
  );
}

export default Home;
