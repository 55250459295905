import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import * as Ai from "react-icons/ai";
import "../Theropies/Therapies.css";
import { useNavigate, useParams } from "react-router";
import { AppContext } from "../../context/AppContext";
import Placeholder from "../../assets/placeholder01.jpg";
import Utils from "../../utils/utils";

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

function Therapies() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    therapies,
    allCategories,
    programsCollections,
    setTherapies,
    getAllScheduleStatus,
    scheduledTherapiesLoading,
    setSelectedScheduleSlot,
    getAllScheduleByTherapy,
    therapists,
  } = useContext(AppContext);
  const [viewMore, setViewMore] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTherapy, setSelectedTherapy] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [bookedSchedule, setBookedSchedule] = useState(null);
  const [therapy, setTherapy] = useState(null);
  const [scheduleList, setScheduleList] = useState(null);
  const [therapist, setTherapist] = useState(null);
  const [dateSlot, setDateSlot] = useState(null);
  const [weekSlot, setWeekSlot] = useState(null);
  useEffect(() => {
    getTherapyDetails();
  }, [therapies]);

  useEffect(() => {
    listenCategoryChange();
  }, [selectedCategory]);

  useEffect(() => {
    getScheduleData();
  }, [id]);

  const getTherapistData = (id) => {
    if (!id) return;
    const data = therapists.find((x) => x._id == id);

    return data;
  };

  const getScheduleData = async () => {
    if (!id) return;

    const payload = {
      therapyId: id,
    };

    await getAllScheduleByTherapy(payload, {
      onSuccess: (res) => {
        if (res.response != null && Array.isArray(res.response)) {
          setScheduleList(res.response);
          return;
        }
      },
      onFailed: (err) => {
        console.error(err);
      },
    });
  };

  const getTherapyDetails = () => {
    const locTherapy = therapies.find((therapy) => therapy.therapyId == id);
    if (!locTherapy) return;
    setTherapy(locTherapy);
  };

  const showMore = () => {
    setViewMore(!viewMore);
  };

  const checkoutDir = (id) => {
    navigate("/therapy-step/:id" + id);
  };

  const openTherapyDetailsModal = (therapy) => {
    if (!therapy) return;
    setSelectedTherapy(therapy);
    setOpenModal(true);
  };

  const closeTherapyDetailsModal = () => {
    setSelectedTherapy(null);
    setOpenModal(false);
  };
  const listenCategoryChange = () => {
    if (selectedCategory == "all") {
      setTherapies(programsCollections);
      return;
    }

    if (selectedCategory == "popular") {
      const filteredPrograms = programsCollections.filter((program) => {
        return program != null && program.therapyDetails.popular;
      });
      setTherapies(filteredPrograms);
      return;
    }

    const filteredPrograms = programsCollections.filter((program) => {
      return (
        program != null &&
        program.therapyDetails.category._id == selectedCategory
      );
    });
    setTherapies(filteredPrograms);
  };
  const hasAvailableSlot = (elem) => {
    console.log(elem, "elem data");
    const check =
      elem != null &&
      elem.schedules.therapyId == id &&
      !Boolean(
        bookedSchedule != null &&
          bookedSchedule.find(
            (x) =>
              x.date == Utils.formatDate(elem.date) &&
              x.therapistId == elem.therapistId &&
              elem.schedules.startTime == x.startTime &&
              elem.schedules.endTime == x.endTime &&
              x.bookingStatus != "CANCELED"
          )
      );
    return check;
  };

  const hasWeeklyAvailableSlot = (schedule) => {
    return weekDays.some((day) => {
      const daySchedule = schedule[day];
      return (
        daySchedule != null &&
        daySchedule.length !== 0 &&
        daySchedule.some((dayScheduleItem) => {
          const weekDate = Utils.formatDate(dayScheduleItem.date);
          return !Boolean(
            bookedSchedule != null &&
              bookedSchedule.find(
                (x) =>
                  x.date == weekDate &&
                  x.therapistId == dayScheduleItem.therapistId &&
                  dayScheduleItem.startTime == x.startTime &&
                  dayScheduleItem.endTime == x.endTime &&
                  x.bookingStatus != "CANCELED"
              )
          );
        })
      );
    });
  };

  const onCategoryClick = (id) => setSelectedCategory(id);

  const clickToRight = () => {
    const container = document.querySelector(".filteredTherapies");
    container.scrollLeft += 180;
  };
  const clickToLeft = () => {
    const container = document.querySelector(".filteredTherapies");
    container.scrollLeft -= 180;
  };
  const handlePayment = () => {
    navigate("/payment");
  };

  const onSelectScheduleSlot = ({ schedule, slot }) => {
    if (!slot || !schedule) return;
    setSelectedScheduleSlot({ schedule, slot });
    sessionStorage.setItem(
      "selectedScheduleSlot",
      JSON.stringify({ schedule, slot })
    );
    setTimeout(() => {
      navigate(`/payment/${id}`);
    }, 800);
  };

  const getScheduleSlotData = async () => {
    if (!id) return;
    const payload = {
      therapyId: id,
    };
    await getAllScheduleStatus(payload, {
      onSuccess: (res) => {
        if (res.data != null && Array.isArray(res.data)) {
          setBookedSchedule(res.data);
          return;
        }
      },
      onFailed: (err) => {
        console.error(err);
      },
    });
  };
  useEffect(() => {
    getScheduleSlotData();
  }, [id]);

  useEffect(() => {
    const filteredWeekSlot =
      bookedSchedule != null &&
      bookedSchedule.filter((entry) => !entry.dateSchedule);
    setWeekSlot(filteredWeekSlot);

    const filterDateSlot =
      bookedSchedule != null &&
      bookedSchedule.filter((entry) => entry.dateSchedule);
    setDateSlot(filterDateSlot);
  }, [bookedSchedule]);

  // console.log("weeklly->", weekSlot);
  // console.log("date->", dateSlot);
  // console.log("bookedsche", scheduleList);

  return (
    <>
      <Header />{" "}
      <section className="therapies">
        <div className="container">
          <div className="new-data">
            <h3 className="m-0">
              {therapy != null ? therapy.therapyDetails.therapyName : "N/A"}
            </h3>
            <br />
            <p className="therapy_Des">
              {therapy != null
                ? therapy.therapyDetails.therapyDescription
                : "N/A"}
            </p>
          </div>

          {scheduleList != null && scheduleList.length !== 0 ? (
            scheduleList.map((list, index) => {
              const dateSchedule = list.DateSchedule;
              const weeklySchedule = list.weeklySchedule;
              const therapist = getTherapistData(list.therapistId);
              const noAvailableSlots =
                (!dateSchedule ||
                  dateSchedule.every((elem) => !hasAvailableSlot(elem))) &&
                (!weeklySchedule ||
                  weeklySchedule.every(
                    (schedule) => !hasWeeklyAvailableSlot(schedule)
                  ));

              if (noAvailableSlots) {
                return null;
              }

              return (
                <div className="col-md-12 m-3 slot-list" key={index}>
                  <div className="card p-3 mt-3">
                    <div className="row">
                      <div className="col-md-3 col-sm-12">
                        <div className="mx-auto text-center image-div">
                          <img
                            src={
                              therapist.photo != null
                                ? process.env.REACT_APP_AWS_IMAGE_URL +
                                  therapist.photo
                                : Placeholder
                            }
                            className="ima-placeholder"
                          />
                        </div>
                        <h6 className="text-center inst-card-name">
                          {therapist.firstName + " " + therapist.lastName}
                        </h6>
                        <p className="small-head">{therapist.desigination}</p>
                      </div>
                      <div className="col-md-9 col-sm-12">
                        <h6 className="text-center inst-card-name mb-3">
                          Available Booking Slots
                        </h6>
                        <div className="row">
                          {dateSchedule != null &&
                            dateSchedule.length != 0 &&
                            dateSchedule.map((elem, index) => {
                              const weekDayDate = Utils.formatDate(elem.date);
                              const check = Boolean(
                                bookedSchedule != null &&
                                  bookedSchedule.find(
                                    (x) =>
                                      x.date == weekDayDate &&
                                      x.therapistId == list.therapistId &&
                                      elem.schedules.startTime == x.startTime &&
                                      elem.schedules.endTime == x.endTime &&
                                      x.bookingStatus != "CANCELED"
                                  )
                              );
                              const today = new Date();
                              const slotDate = new Date(elem.date);
                              // console.log(
                              //   "bookedSchedule==>",
                              //   bookedSchedule != null &&
                              //     bookedSchedule.find(
                              //       (x) =>
                              //         x.date == weekDayDate &&
                              //         x.therapistId == list.therapistId &&
                              //         elem.schedules.startTime == x.startTime &&
                              //         elem.schedules.endTime == x.endTime &&
                              //         x.bookingStatus != "CANCELED"
                              //     )
                              // );
                              
                              // Check if the slot date is today
                              const isToday =
                                slotDate.toDateString() ===
                                today.toDateString();

                              // Convert the slot's start time to minutes since midnight
                              const slotStartTime =
                                parseInt(
                                  elem.schedules.startTime.split(":")[0]
                                ) *
                                  60 +
                                parseInt(
                                  elem.schedules.startTime.split(":")[1]
                                );

                              // Get the current time in minutes since midnight
                              const currentTime =
                                today.getHours() * 60 + today.getMinutes();

                              // Determine if the slot should be displayed
                              const shouldDisplaySlot =
                                (isToday && slotStartTime > currentTime) || // If today, only show future slots
                                slotDate > today; // Otherwise, only show slots on future dates

                              if (
                                elem != null &&
                                elem.schedules.therapyId == id &&
                                !check &&
                                shouldDisplaySlot
                              ) {
                                const scheduleData = elem.schedules;

                                return (
                                  <>
                                    <div
                                      className="col-md-3"
                                      key={index}
                                      onClick={() => {
                                        return onSelectScheduleSlot({
                                          schedule: {
                                            ...elem,
                                            therapistDetails: therapist,
                                          },
                                          slot: {
                                            ...scheduleData,
                                            date: elem.date,
                                            theraphyDetails:
                                              therapy.therapyDetails,
                                            type: "Date",
                                            scheduleParentId: elem._id,
                                          },
                                        });
                                      }}
                                    >
                                      <div className="slot-card">
                                        <div className="slot-date">
                                          {Utils.formatDate(elem.date)}
                                        </div>
                                        <div className="slot-date">
                                          {scheduleData.startTime != null
                                            ? scheduleData.startTime
                                            : "00:00 00"}
                                          -{" "}
                                          {scheduleData.endTime != null
                                            ? scheduleData.endTime
                                            : "00:00 00"}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              } else {
                                return null;
                              }
                            })}
                          {weeklySchedule != null &&
                            weeklySchedule.length != 0 &&
                            weeklySchedule.map((schedule, index) => {
                              const weeklyBookingStatusList =
                                schedule.bookingDetails;

                              return weekDays.map((day) => {
                                return (
                                  schedule[day] != null &&
                                  schedule[day].length != 0 &&
                                  schedule[day].map((daySchedule) => {
                                    if (daySchedule.therapyId != id) {
                                      return <></>;
                                    }

                                    const numOfWeeks = 4;
                                    const startingDay = day;
                                    const scheduleAvailableDays =
                                      Utils.getNextDaysOfWeek(
                                        numOfWeeks,
                                        startingDay
                                      );

                                    return scheduleAvailableDays.map(
                                      (day, index) => {
                                        const weekDate = Utils.formatDate(day);
                                        const check = Boolean(
                                          bookedSchedule != null &&
                                            bookedSchedule.find(
                                              (x) =>
                                                x.date == weekDate &&
                                                x.therapistId ==
                                                  list.therapistId &&
                                                daySchedule.startTime ==
                                                  x.startTime &&
                                                daySchedule.endTime ==
                                                  x.endTime &&
                                                x.bookingStatus != "CANCELED"
                                            )
                                        );

                                        if (!check) {
                                          return (
                                            <div
                                              className="col-md-3"
                                              key={index}
                                              onClick={() => {
                                                return onSelectScheduleSlot({
                                                  schedule: {
                                                    ...schedule,
                                                    therapistDetails: therapist,
                                                  },
                                                  slot: {
                                                    ...daySchedule,
                                                    date: day,
                                                    theraphyDetails:
                                                      therapy.therapyDetails,
                                                    scheduleParentId:
                                                      schedule._id,
                                                  },
                                                });
                                              }}
                                            >
                                              <div className="slot-card">
                                                <div className="slot-date">
                                                  {Utils.formatDate(day)}
                                                </div>
                                                <div className="slot-date">
                                                  {`${daySchedule.startTime} : ${daySchedule.endTime}`}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                    );
                                  })
                                );
                              });
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="container">
              <div className="col-md-10">
                <div className="card text-center mx-auto">
                  <h6 className="m-3">No Slots Available</h6>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Therapies;
