import { useState } from "react";
import { APIEndpoints } from "../constants/ApiEndPoints";
import BaseClient from "../helpers/base_client";

const useGuest = () => {
  const [guestLoading, setGuestLoading] = useState(false);

  const addGuestParticipant = async (payload, { onSuccess, onFailed }) => {
    try {
      setGuestLoading(true);
      await BaseClient.post(APIEndpoints.participateGuest, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setGuestLoading(false);
    }
  };
  const verifyGuest = async (payload, { onSuccess, onFailed }) => {
    try {
      setGuestLoading(true);
      await BaseClient.post(APIEndpoints.VerifyGuest, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setGuestLoading(false);
    }
  };

  const getGuestData = async (payload, { onSuccess, onFailed }) => {
    try {
      setGuestLoading(true);
      await BaseClient.post(APIEndpoints.getGuestList, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setGuestLoading(false);
    }
  };
  return { addGuestParticipant, guestLoading, verifyGuest, getGuestData };
};
export default useGuest;
